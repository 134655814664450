












































import { computed, defineComponent, onMounted, reactive, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import { DataOptions } from 'vuetify'

export default defineComponent({
  setup(_, { root }) {
    const user = computed(() => root.$store.getters['user/getData'])

    const state = reactive({
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10
      },
      items: [],
      total: 0
    })

    const fetchData = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance
      const { page, itemsPerPage } = state.options as DataOptions

      axiosInstance.get('chat', { params: { page, itemsPerPage } })
        .then(({ data: { chats, total } }) => {
          state.items = chats
          state.total = total
        })
        .catch(() => [])
    }

    watch(() => state.options, fetchData, { deep: true })

    onMounted(fetchData)

    return { state, user }
  }
})
